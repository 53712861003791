import Vue from "vue";
import Router from "vue-router";
import { TokenService } from "@services";
import store from "@/store";
import { tracer } from "@/diagnostics/tracer.js";

import DefaultLayout from "@views/Index";
import ShareLayout from "@views/Shared";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL || "/",
    routes: [
        {
            path: "/share",
            component: ShareLayout, // () => import("@views/Shared"),
            meta: {
                public: true,
            },
            children: [
                {
                    name: "Form Submission Share",
                    path: "form/submission/:id?/:action?",
                    component: () => import("@views/share/FormSubmission"),
                    props: true,
                    meta: {
                        public: true,
                        noBackground: true,
                        layout: "share-layout",
                    },
                },
            ],
        },
        {
            path: "/",
            component: DefaultLayout, // () => import("@views/Index"),
            meta: {
                public: true,
            },
            children: [
                {
                    name: "Dashboard",
                    path: "",
                    component: () => import("@views/Dashboard"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Login",
                    path: "login",
                    component: () => import("@views/Login"),
                    props: true,
                    meta: {
                        public: true,
                    },
                },
                {
                    name: "Account Help",
                    path: "account-help",
                    component: () => import("@views/AccountHelp"),
                    meta: {
                        public: true,
                    },
                },
                {
                    name: "Reset Password",
                    path: "resetpassword",
                    component: () => import("@views/ResetPassword"),
                    meta: {
                        public: true,
                    },
                },
                {
                    name: "Register",
                    path: "register",
                    component: () => import("@views/Register"),
                    meta: {
                        public: true,
                    },
                },
                {
                    name: "Acrisure",
                    path: "acrisure",
                    component: () => import("@views/Acrisure"),
                    meta: {
                        public: true,
                    },
                },
                {
                    name: "Confirm Email",
                    path: "confirm-email",
                    component: () => import("@views/ConfirmEmail"),
                    meta: {
                        public: true,
                    },
                },
                {
                    name: "Help",
                    path: "help",
                    component: () => import("@views/Help"),
                    meta: {
                        public: true,
                    },
                },
                {
                    name: "Diagnostics",
                    path: "diagnostics",
                    component: () => import("@views/diagnostics/Diagnostics.vue"),
                    meta: {
                        public: true,
                        noBackground: true,
                    },
                },
                {
                    name: "Timeclocks",
                    path: "timeclocks/:tab?",
                    component: () => import("@views/Timeclocks"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },
                // Company Routes Start //

                {
                    name: "Company",
                    path: "company/:tab?",
                    component: () => import("@views/Company"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Company",
                    path: "company/jobs/new",
                    component: () => import("@views/company/jobs/JobsNew"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Company",
                    path: "company/jobs/:id/:tab?",
                    component: () => import("@views/company/jobs/JobDetails"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },

                {
                    name: "Company",
                    path: "company/credit-topics/new",
                    component: () => import("@views/company/credit-topics/TopicForm"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Company",
                    path: "company/credit-topics/:id/:tab?",
                    component: () => import("@views/company/credit-topics/TopicDetails"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },

                {
                    name: "Company",
                    path: "company/instructors/new",
                    component: () => import("@views/company/instructors/InstructorForm"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Company",
                    path: "company/instructors/:id/:tab?",
                    component: () => import("@views/company/instructors/InstructorDetails"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },

                {
                    name: "Company",
                    path: "company/timeclock-categories/new",
                    component: () => import("@views/company/time-categories/TimeCatForm"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Company",
                    path: "company/timeclock-categories/:id/:tab?",
                    component: () => import("@views/company/time-categories/TimeCatDetails"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },

                {
                    name: "Company",
                    path: "company/cost-codes/new",
                    component: () => import("@views/company/cost-codes/CostCodeForm"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Company",
                    path: "company/cost-codes/:id/:tab?",
                    component: () => import("@views/company/cost-codes/CostCodeDetails"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },

                {
                    name: "Company",
                    path: "company/groups/new",
                    component: () => import("@views/company/groups/GroupForm"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Company",
                    path: "company/groups/:id/:tab?",
                    component: () => import("@views/company/groups/GroupDetails"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },

                // Company Routes End //
                {
                    name: "Users",
                    path: "users/:tab?",
                    component: () => import("@views/Users"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "User Details",
                    path: "user/:id/:tab?",
                    component: () => import("@views/UserDetails"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Messages",
                    path: "messages",
                    component: () => import("@views/Messages"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "My Account",
                    path: "account",
                    component: () => import("@views/Account"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Operator Companies",
                    path: "operator/companies",
                    component: () => import("@views/operator/Companies"),
                    meta: {
                        requiresOp: true,
                    },
                },
                {
                    name: "Operator Users",
                    path: "operator/users",
                    component: () => import("@views/operator/Users"),
                    meta: {
                        requiresOp: true,
                    },
                },
                {
                    name: "Operator User Details",
                    path: "operator/user/:id/:tab?",
                    //component: () => import("@views/operator/UsersEdit"),
                    component: () => import("@views/UserDetails"),
                    meta: {
                        requiresOp: true,
                    },
                },
                {
                    name: "Operator Updates",
                    path: "operator/updates",
                    component: () => import("@views/operator/Updates"),
                    meta: {
                        requiresOp: true,
                    },
                },
                {
                    name: "Reports",
                    path: "reports",
                    component: () => import("@views/reports"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Report",
                    path: "reports/:reportid",
                    component: () => import("@views/reports/viewer"),
                    meta: {
                        public: false,
                    },
                },

                {
                    name: "Forms",
                    path: "forms/:tab?",
                    component: () => import("@views/Forms"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Form Details",
                    path: "form/:id?/:tab?",
                    component: () => import("@views/FormDetails"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Form Submission",
                    path: "submission/:id?",
                    component: () => import("@views/FormSubmission"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Form Submission Open Access",
                    path: "open/form/submission/:id?",
                    component: () => import("@views/public/FormSubmission"),
                    props: true,
                    meta: {
                        public: true,
                        noBackground: true,
                    },
                },
                {
                    name: "Training",
                    path: "training",
                    component: () => import("@views/Training"),
                },
                {
                    name: "Training",
                    path: "training/course-details/:id?",
                    component: () => import("@views/TrainingAsset"),
                    props: true,
                },
                {
                    name: "Training",
                    path: "training/course-details/:id/session/:sessionId",
                    component: () => import("@views/TrainingWebinar"),
                    props: true,
                },
                {
                    name: "Documents",
                    path: "documents",
                    component: () => import("@views/Documents"),
                },
                {
                    name: "Log-It Logs",
                    path: "logit",
                    component: () => import("@views/LogIt"),
                },

                {
                    name: "Logit",
                    path: "logit/:tab?",
                    component: () => import("@views/LogIt"),
                    props: true,
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Log-It",
                    path: "logit/log/:id",
                    component: () => import("@views/LogItLog"),
                    props: true,
                },
                {
                    name: "Assets",
                    path: "documents/:id",
                    component: () => import("@views/DocAssets"),
                    props(route) {
                        return {
                            docId: parseInt(route.params.id),
                        };
                    },
                },
                {
                    name: "Billing",
                    path: "billing",
                    component: () => import("@views/Billing"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Assets",
                    path: "assets",
                    component: () => import("@views/Assets"),
                    meta: {
                        public: false,
                    },
                },
                {
                    name: "Operator Paper OS",
                    path: "operator/paperos",
                    component: () => import("@views/operator/PaperOs"),
                    meta: {
                        requiresOp: true,
                    },
                },
                {
                    name: "PageNotFound",
                    path: "*",
                    component: () => import("@views/PageNotFound"),
                    meta: {
                        public: false,
                    },
                },
            ],
        },
    ],
});
router.beforeEach((to, from, next) => {
    const requiresOpStatus = !!to.meta?.requiredOp;
    const isPublic = !!to.meta?.public;
    const isAuthenticated = !!TokenService.getToken();

    if (isAuthenticated && !store.state.user.userCreds) {
        store.dispatch("user/setUserCreds");
    }

    if (!isPublic && !isAuthenticated) {
        tracer.info("[VUE] Navigation | " + to?.fullPath + " | Not authenticated, redirecting to Login");

        next({
            path: "/login",
            query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
        });
    } else if (isAuthenticated && to.fullPath === "/login") {
        tracer.info("[VUE] Navigation | " + to?.fullPath + " | Already authenticated, redirecting to Home");
        // Do not allow user to visit login page or register page if they are logged in
        next("/");
    } else if (requiresOpStatus && isAuthenticated) {
        const userCreds = store.state.user.userCreds;

        if (userCreds) {
            if (userCreds.isOperator == "True" || userCreds.isDeveloper == "True") {
                tracer.info("[VUE] Navigation | " + to?.fullPath);
                next();
            } else {
                tracer.info("[VUE] Navigation | " + to?.fullPath + " | Not Operator, returning to prior location");
                next({ path: from.path, replace: true });
            }
        } else {
            tracer.info("[VUE] Navigation | " + to?.fullPath + " | Not Operator, returning to prior location");
            next({ path: from.path, replace: true });
        }
    } else {
        tracer.info("[VUE] Navigation | " + to?.fullPath);
        next();
    }
});

export default router;

export function TabRouteHelper(to, from, next, tabs) {
    if (tabs.length === 0) {
        next();
        return;
    }

    if (!tabs.some((x) => x.path === to.params?.tab)) {
        let basePath = to.path.substring(0, to.path.length - (to.params?.tab?.length ?? 0));

        basePath = `${basePath.trimRight("/")}/`; // Ensure we have a trailing slash. Trim + readd is quicker than checking if the last char is a slash or not.

        next({ path: `${basePath}${tabs[0].path}`, replace: true });

        return;
    }

    next();
}
